
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
    TmFormLine,
  },
  setup() {
    const phone = ref('+1 (243) 542-5436')
    const routing = ref('disabled')

    return {
      phone,
      routing,
    }
  },
})
